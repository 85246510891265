<template>
  <documents-component :is-presale="true"></documents-component>
</template>

<script>
import DocumentsComponent from "@/router/views/documents/index";
export default {
  components: {
    DocumentsComponent,
  },
};
</script>

<style></style>
